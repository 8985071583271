// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicAdPromptSelected,
  DefaultAdPromptSelectedProps
} from "./plasmic/idea_stats_v_1/PlasmicAdPromptSelected";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { useLocation, useNavigate } from "react-router-dom";
import {getAdImg, renderTemplate1} from "../utils/SharedDataTools"
import AdTemplate1 from "./AdTemplate1";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface AdPromptSelectedProps extends Omit<DefaultAdPromptSelectedProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultAdPromptSelectedProps altogether and have
// total control over the props for your component.
export interface AdPromptSelectedProps extends DefaultAdPromptSelectedProps {
  adImgSrc: string;
  setAdImgSrc: any;
  selectedAdTemplate: any;
  setSelectedAdTemplate: any;
}

function AdPromptSelected_(
  {adImgSrc, setAdImgSrc, selectedAdTemplate, setSelectedAdTemplate, ...props}: AdPromptSelectedProps,
  ref: HTMLElementRefOf<"div">
) {
  // Use PlasmicAdPromptSelected to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicAdPromptSelected are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all AdPromptSelectedProps here, but feel free
  // to do whatever works for you.

  let navigate = useNavigate();

  let imgSrc = adImgSrc;

  let [titleString, setTitleString] = React.useState("Hello");
  let [bodyString, setBodyString] = React.useState("Ad body. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.");
  let [callToActionString, setCallToActionString] = React.useState("Sign Up");
  let [componentRef, setComponentRef] = React.useState({})

  let templateData = {
    title: titleString,
    body: bodyString,
    callToAction: callToActionString,
    adImgSrc: imgSrc,
    componentRef: componentRef,
    setComponentRef: setComponentRef
  }

  // Do this only once on startup.
  React.useEffect(() => {
    if (Object.keys( selectedAdTemplate).length > 0){
      setTitleString(selectedAdTemplate.title);
      setBodyString(selectedAdTemplate.body);
      setCallToActionString(selectedAdTemplate.callToAction);
      setComponentRef(selectedAdTemplate.setComponentRef);
    }
  }, [])

  // let adTemplateInstance = <AdTemplate1 title={ <h2 style={{fontSize: 48, fontWeight: "bold", color:"white"}}> {titleString} </h2>} body={bodyString} />;
  

  return <PlasmicAdPromptSelected 
  
    root={{ ref }}

    adTemplate1={ renderTemplate1(templateData) }
    
    selectButton={{
      onClick: () => {
        setSelectedAdTemplate(templateData); 
        navigate("/finished-ad", {state:{ adImgSrc: imgSrc }});
      }
    }}

    titleInput={{
      onChange: (event) => {
        setTitleString(event.target.value);
      }  
    }}

    bodyArea={{
      onChange:  (event) => {
        setBodyString(event.target.value);
      }
    }}

    buttonIntput={{
      onChange:  (event) => {
        setCallToActionString(event.target.value);
      }
    }}

    {...props} />;
}

const AdPromptSelected = React.forwardRef(AdPromptSelected_);
export default AdPromptSelected;
