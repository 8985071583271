// import { Location } from "react-router";

export function getAdImg(location: any, defaultImgSrc?: String | undefined){
    let adImgSrc = process.env.REACT_APP_DEFAULT_AD_IMG;
    if (location.state && "adImgSrc" in location.state) {
        adImgSrc =  location.state.adImgSrc;
    }
    return adImgSrc;
}

export function renderTemplate1(templateData: any){
    return {
        id: templateData.id,

        adImgSrc: templateData.adImgSrc, 

        // Customization in template.
        title: <h2 style={{fontSize: 48, fontWeight: "bold", color:"white", paddingBottom:0, marginBottom:0}}> {templateData.title}</h2>,
        body: <div style={{fontSize: 30}} > {templateData.body}</div>,
        callToAction: templateData.callToAction,
        componentRef: templateData.componentRef,
        setComponentRef: templateData.setComponentRef
    };
}
