// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, httpsCallable } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtgjsbI42Gj-fZNHzRMGzQCmLNNmU0HKE",
  authDomain: "ideastatsapp.firebaseapp.com",
  projectId: "ideastatsapp",
  storageBucket: "ideastatsapp.appspot.com",
  messagingSenderId: "390701426508",
  appId: "1:390701426508:web:b423b5c1c9701e075191e9",
  measurementId: "G-H3HDZDXGLL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const functions = getFunctions(app);

const getChatGPTImageAppFunc = httpsCallable(
  functions,
  "getChatGPTImageAppFunc"
);

// getChatGPTImageAppFunc.setTimeout(120);

export default getChatGPTImageAppFunc;
