// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from "react";
import {
  PlasmicFinishedAd,
  DefaultFinishedAdProps
} from "./plasmic/idea_stats_v_1/PlasmicFinishedAd";
import { HTMLElementRefOf } from "@plasmicapp/react-web";
import { renderTemplate1} from "../utils/SharedDataTools"
import { toJpeg } from "html-to-image";

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface FinishedAdProps extends Omit<DefaultFinishedAdProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultFinishedAdProps altogether and have
// total control over the props for your component.
export interface FinishedAdProps extends DefaultFinishedAdProps {
  selectedAdTemplate: any
}

function FinishedAd_({selectedAdTemplate, ...props}: FinishedAdProps, ref: HTMLElementRefOf<"div">) {
  // Use PlasmicFinishedAd to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicFinishedAd are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all FinishedAdProps here, but feel free
  // to do whatever works for you.

  let [componentRef, setComponentRef] = React.useState({})

  selectedAdTemplate.setComponentRef = setComponentRef;
  selectedAdTemplate.componentRef = componentRef;
  selectedAdTemplate.id = "finalAd";

  return <PlasmicFinishedAd
          root={{ ref }}
          
          finalAdTemplate={
            renderTemplate1( selectedAdTemplate)
          }

          downloadButton={{
            onClick: () => {
              console.log(selectedAdTemplate);

              toJpeg(document.getElementById('finalAd')!, { quality: 0.95 })
                .then(function (dataUrl) {
                  let link = document.createElement('a');
                  link.download = 'ad-img.jpeg';
                  link.href = dataUrl;
                  link.click();
                }).then(() => {
                  console.log("Success"); // Success!
                },
                (reason) => {
                  console.error("Failure:", reason); // Error!
                },)
                ;
            } 
          }}

          {...props} />;
}

const FinishedAd = React.forwardRef(FinishedAd_);
export default FinishedAd;
